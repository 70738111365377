.booking-gaps {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: pxToRem(20);
    padding: pxToRem(30);
    margin-top: pxToRem(12);
    background-color: $color-white;
    @include getFigmaStyle('Shadow/MD');

    p {
        font-weight: 700;
    }

    .button.button--primary.button--md {
        width: auto;
    }
}

.booking-gaps--room {
    justify-content: center;
    margin-top: initial;
    text-align: center;
}

@include media-breakpoint-down(md) {
    .booking-gaps--fastbooking {
        margin-top: pxToRem(8);
    }
}