@import "../../dist/zimmerfrei";

.jsx-button,
.jsx-button:active,
.jsx-button:visited {
    height: 40px;
    line-height: 38px;
    color: #fff;
    background: $color-primary;
    border: 1px solid transparent;
}


.jsx-button:hover,  {
    background: $color-primary-hover;
}

.is-open .jsx-button {
    color: $color-primary;
    background: transparent;
    border-color: transparent;
}

//.is-open .jsx-button:before {
//    background-image: svg-load('check.svg', fill=#000);
//}

.jsx-zimmerfrei.is-load:before {
    background: rgba($color-secondary-bg, 0.7);
}

.jsx-zimmerfrei.is-load:after {
    border: 2px solid $color-primary;
}

.jsx-zimmerfrei-swipe {
    color: #fff;
    background: $color-primary;
}

//.jsx-zimmerfrei-swipe.is-prev span {
//    background-image: svg-load('left.svg', fill=#fff);
//}

//.jsx-zimmerfrei-swipe.is-next span {
//    background-image: svg-load('right.svg', fill=#fff);
//}

.jsx-zimmerfrei-spacer {
    font-size: 14px;
    background: rgba($font-color-primary, 0.05);
}

//.jsx-zimmerfrei-spacer span {
//    background-image: svg-load('down.svg', fill=#000);
//}

.jsx-zimmerfrei-popover {
    color: #fff;
    background: $font-color-primary;
}

.jsx-zimmerfrei-popover > div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.jsx-zimmerfrei-dates {
    background: #fff;
    border-bottom: 1px solid rgba($font-color-primary, 0.2);
}

.jsx-zimmerfrei-date {
    font-size: 14px;
}

.jsx-zimmerfrei-date .jsx-day {
    background: rgba($font-color-primary, 0.05);
}

.jsx-zimmerfrei-date.is-weekend .jsx-day {
    background: rgba($font-color-primary, 0.1);
}

.jsx-zimmerfrei-room {
    font-size: 14px;
    background: rgba($font-color-primary, 0.05);
    border-bottom: 1px solid rgba($font-color-primary, 0.1);
}

.jsx-zimmerfrei-price {
    font-size: 14px;
    border-bottom: 1px solid rgba($font-color-primary, 0.1);
}

.jsx-zimmerfrei-price:not(.is-clickable) {
    color: rgba($font-color-primary, 0.5);
}

.jsx-zimmerfrei-price.is-arrive:before {
    background: $color-primary;
}

.jsx-zimmerfrei-price.is-selected,
.jsx-zimmerfrei-price.is-between,
.jsx-zimmerfrei-price.is-arrive, {
    background: rgba($color-primary, 0.1) !important;
}

.jsx-zimmerfrei-price:not(.is-disabled):not(.is-inactive):hover {
    background: rgba($color-primary, 0.05);
}

.jsx-zimmerfrei-clear {
    background: $color-primary;
}

//.jsx-zimmerfrei-clear span {
//    background-image: svg-load('times.svg', fill=#fff);
//}

.jsx-zimmerfrei-sticky {
    padding: 0 20px;
    background: $color-secondary-bg;
}

.jsx-zimmerfrei-notice__title {
    text-align: center;
    margin-bottom: 20px;
}

.jsx-zimmerfrei-notice__title h2 {
    font-size: 32px;
}

.jsx-zimmerfrei-notice__disclaim {
    color: $color-danger;
    text-align: center;
    margin-bottom: 20px;
}

.jsx-zimmerfrei-notice__content {
    text-align: center;
    margin-bottom: 20px;
}

.jsx-zimmerfrei-notice__button {
    text-align: center;
}

.jsw-zimmerfrei-reviews {
    position: relative;
    padding: 40px 0 1px 0;
    background: $color-background;
}

.jsw-zimmerfrei-reviews:before {
    border-top-color: $color-secondary-bg !important;
}

.jsx-zimmerfrei-review {
    background: $color-secondary-bg;
    margin-bottom: 10px;
}

.jsx-zimmerfrei-review__index {
    width: 70px;
    border-right: 1px solid rgba($font-color-primary, 0.1);
}

.jsx-zimmerfrei-review__range {
    width: 200px;
}

@media (max-width: 640px) {
    .jsx-zimmerfrei-review__range {
        border-bottom: 1px solid rgba($font-color-primary, 0.1);
    }
}

@media (min-width: 641px) {
    .jsx-zimmerfrei-review__range {
        border-right: 1px solid rgba($font-color-primary, 0.1);
    }
}

.jsx-zimmerfrei-review__total {
    width: 140px;
}

@media (max-width: 640px) {
    .jsx-zimmerfrei-review__total {
        border-bottom: 1px solid rgba($font-color-primary, 0.1);
    }
}

@media (min-width: 641px) {
    .jsx-zimmerfrei-review__total {
        border-right: 1px solid rgba($font-color-primary, 0.1);
    }
}

@media (min-width: 641px) {
    .jsx-zimmerfrei-review__button a {
        width: 180px;
    }
}

.jsw-zimmerfrei-total {
    padding: 1px 0 40px 0;
    background: $color-background;
}

.jsx-zimmerfrei-total {
    color: #fff;
    background: $color-primary;
}

.jsx-zimmerfrei-total > div {
    padding: 20px;
}

.jsx-zimmerfrei-total__total {
    padding-left: 0;
}