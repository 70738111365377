@import "@sx-root/hns/zimmerfrei/src/scss/theme";

.jsx-zimmerfrei {
    margin-bottom: -1px;
    padding-bottom: 1px;
    background: $color-white;
    font-family: "TangerSerifMedium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.jsx-link {
    color: $color-primary;
}

.jsx-zimmerfrei-calendar {
    margin-bottom: 40px;
}

.jsx-zimmerfrei-dates {
    border-bottom: 2px solid $color-primary;
}

.jsx-zimmerfrei-spacer {
    border-radius: 4px 0 0 0;
}

.jsx-zimmerfrei-date .jsx-day, .jsx-zimmerfrei-spacer {
    height: 42px;
}

.jsx-zimmerfrei-date .jsx-day {
    font-size: 15px;
    font-weight: 500;
    font-family: "Tanger Serif Medium";
}

.jsx-zimmerfrei-room {
    background: rgba($color-primary, 0.05);
}

.jsx-zimmerfrei-price.is-selected, .jsx-zimmerfrei-price.is-between, .jsx-zimmerfrei-price.is-arrive {
    background: rgba($color-primary, 0.15) !important;
}

.jsx-zimmerfrei-spacer {
    background: rgba($color-primary, 0.1);
}

.jsx-zimmerfrei-spacer span {
    font-size: 14px;
}

.jsx-zimmerfrei-date .jsx-day {
    background: rgba($color-primary, 0.1);
}

.jsx-zimmerfrei-date.is-weekend .jsx-day {
    background: rgba($color-primary, 0.2);
}

.jsx-zimmerfrei-room {
    font-size: 16px;
    font-family: "Tanger Serif Medium";
}

.jsx-zimmerfrei-sticky {
    border-radius: 4px 4px 0 0;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2);
}
.jsx-zimmerfrei-sticky__title, .jsx-zimmerfrei-total__title, .jsx-zimmerfrei-review__room {
    font-size: 19px;
    font-family: "Tanger Serif Medium";
}

.jsx-zimmerfrei-swipe {
    width: 44px;
    height: 44px;
    bottom: -2px;
}

.jsx-button {
    font-size: 1.125rem;
    font-family: "Tanger Serif Medium";
    font-weight: 600;
    font-style: normal;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
}

.jsx-zimmerfrei-popover {
    background-color: rgb(50, 53, 59);
    border-radius: 4px;
    margin-top: 6px;
}

.jsw-zimmerfrei-notice {
    padding: 70px 0 90px;
}

.jsw-zimmerfrei-reviews, .jsw-zimmerfrei-total {
    background-color: rgb(50, 53, 59);
}

.jsx-zimmerfrei-notice__content {
    color: rgb(84, 89, 99);
}

.jsx-zimmerfrei-notice__disclaim {
    color: $color-primary;
}

.jsx-zimmerfrei-review, .jsx-zimmerfrei-total {
    border-radius: 4px;
}
